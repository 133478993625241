import React from 'react';

import'./style.css';
import ScrollAnimation from 'react-animate-on-scroll';
function Footer() {
  return (
    <div className="footer_wrapper">
      <ScrollAnimation animateIn="fadeIn" animateOnce>
        <div className="footer_container">
          
            <div className="title_default">COMPARTILHE COM SEUS AMIGOS</div>

            <div className="social_icons_wrapper">
                <div><i class="fab fa-whatsapp"></i></div>
                <div><i class="fab fa-facebook-f"></i></div>
                <div><i class="fab fa-instagram"></i></div>
                <div><i class="far fa-envelope"></i></div>

            </div>

        </div>
        </ScrollAnimation>
    </div>
  );
}

export default Footer;