import React from 'react';

import'./style.css';
import'./responsive.css';
import ScrollAnimation from 'react-animate-on-scroll';
function Vantagens() {
  return (
        <div className="vantagens_wrapper">
            <div className="box_vantagens_container">
            <ScrollAnimation animateIn="fadeIn" animateOnce>
                <div className="title_default">VANTAGES DO NOSSO PROGRAMA DE AFILIADOS</div>
                </ScrollAnimation>
                <div className="items_wrapper">


                <ScrollAnimation animateIn="fadeIn" animateOnce>
                    <div className="list_wrapper">

                            <div><i class="far fa-check-circle"></i>&nbsp; Cadastro simples e gratuito</div>
                            <div><i class="far fa-check-circle"></i>&nbsp; Não precisa de CNPJ</div>
                            <div><i class="far fa-check-circle"></i>&nbsp; Painel de acompanhamento de vendas</div>
                            <div><i class="far fa-check-circle"></i>&nbsp; Equipe de apoio</div>
                            <div><i class="far fa-check-circle"></i>&nbsp; Rastreamento de cliques e origens de vendas</div>
                            <div><i class="far fa-check-circle"></i>&nbsp; Links personalizados</div>
                            <div><i class="far fa-check-circle"></i>&nbsp; Materiais para divulgação</div>
                    </div>
                    </ScrollAnimation>
                    <div className='taxa_wrapper'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="684" height="684" viewBox="0 0 684 684">
                            <defs>
                            <filter id="Elipse_2" x="0" y="0" width="684" height="684" filterUnits="userSpaceOnUse">
                            <feOffset dy="14" input="SourceAlpha"/>
                            <feGaussianBlur stdDeviation="6" result="blur"/>
                            <feFlood flood-opacity="0.341"/>
                            <feComposite operator="in" in2="blur"/>
                            <feComposite in="SourceGraphic"/>
                            </filter>
                            </defs>
                            <g id="Grupo_2" data-name="Grupo 2" transform="translate(-101 -212)">
                            <g transform="matrix(1, 0, 0, 1, 101, 212)" filter="url(#Elipse_2)">
                            <circle id="Elipse_2-2" data-name="Elipse 2" cx="324" cy="324" r="324" transform="translate(18 4)" fill="#fff"/>
                            </g>
                            <text id="TAXA_DE_CONVERSÃO_80_MAIOR_QUE_OS_OUTROS_PLAYERS_" data-name="TAXA DE CONVERSÃO
                            80%
                            MAIOR QUE OS OUTROS PLAYERS

                            " transform="translate(443 451)" fill="#707070" font-size="31" font-family="SegoeUI, Segoe UI"><tspan x="-152.215" y="0">TAXA DE CONVERSÃO</tspan><tspan font-size="140" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="-141.23" y="159">80%</tspan></tspan><tspan font-size="27"><tspan x="-204.55" y="223">MAIOR QUE OS OUTROS PLAYERS</tspan></tspan><tspan font-size="20"><tspan x="0" y="252"></tspan><tspan x="0" y="279"></tspan></tspan></text>
                            <rect id="Retângulo_1" data-name="Retângulo 1" width="337" height="87" rx="43.5" transform="translate(261 717)" fill="#f50"/>
                            <text id="Comece_agora" data-name="Comece agora" transform="translate(430 773)" fill="#fff" font-size="31" font-family="SegoeUI, Segoe UI"><tspan x="-115.592" y="0">COMECE AGORA</tspan></text>
                            </g>
                        </svg>

                    </div>
                </div>
            </div>
          
        </div>
  );
}

export default Vantagens;