import React from 'react';
import './App.css';
// import Routes from './Routes';
import Menu from './Components/Menu';
import Banner from './Components/Banner';
import Funcionamento from './Components/Funcionamento';
import Vantagens from './Components/Vantagens';
import Footer from './Components/Footer';
import Sub from './Components/Sub';
import Cadastre from './Components/Cadastre';

function App() {
  return (
    <div className="App">
        <Menu/>
        <Banner/>
        <Funcionamento/>
        <Vantagens/>
        <Cadastre/>
        <Footer/>
        <Sub/>
    </div>
  );
}

export default App;


// "homepage": "https://mav.com.br/programa-de-afiliados/",