import React from 'react';

import './style.css';

function Sub() {
  return <div className="direitos">
      Todos os direitos reservados @2019 – MAV Tecnologia
  </div>;
}

export default Sub;