import React from 'react';

import'./style.css';
import'./responsive.css';
import ScrollAnimation from 'react-animate-on-scroll';
function Cadastre() {
  return (
    <div className="cadastre_wrapper">
    <ScrollAnimation animateIn="fadeIn" animateOnce>
        <div className="text_cadastre">
            <p>
            CLIQUE AGORA NO BOTÃO ABAIXO E CADASTRE-SE
            </p>
            <a href=" https://afiliados.mav.com.br/usuarios/cadastro"><button>CADASTRE-SE</button></a>
        </div>
        </ScrollAnimation>
    </div>
  );
}

export default Cadastre;