import React from 'react';

import'./style.css';
import'./responsive.css';
import question from '../../Assets/img/quetion.png'
import money from '../../Assets/img/money.png'
import panel from '../../Assets/img/panel.png'
import ScrollAnimation from 'react-animate-on-scroll';

function Funcionamento() {
  return (
  <div className="funcionamento_wrapper">
                <div className="title_category">Indique e lucre pra sempre!</div>
                <div className="sub_title">Outros programas de afiliados pagam um valor fixo por indicação e lucram o resto da vida com os clientes que você indicou. Na Umbler você continua recebendo dinheiro em cima das suas indicações... para sempre!</div>
      <div className="funcionamento_boxes">

        
      <ScrollAnimation animateIn="fadeIn" animateOnce>
          <div className="box">

            <div className="text_area">
              <div className="title">Como funciona?</div> 
              <div className="text">
              Em nosso programa de afiliados, você terá uma área exclusiva onde poderá gerar links dos nossos produtos para divulgar para a sua audiência,
              seja ela uma lista de e-mails, Instagram, Youtube, Blog/Site, Facebook, Whatsapp ou qualquer mídia onde exista tráfego qualificado.
              </div>
            </div>
            <div className="image_area">
              <img src={question} alt="question" />
            </div>
    
          </div>
          </ScrollAnimation>


          <ScrollAnimation animateIn="fadeIn" animateOnce>
          <div className="box">

          <div className="text_area">
            <div className="title">Comissões</div> 
            <div className="text">
            Após um cliente indicado por você contratar um de nossos serviços e pagar a primeira mensalidade, ela será sua!<br/><br/> Por exemplo, se um cliente indicado por você adquirir um plano de R$299, você receberá R$299 de comissão.
            </div>
            </div>

            <div className="image_area">
            <img src={money} alt="question" />
            </div>
          </div>
          </ScrollAnimation>


          <ScrollAnimation animateIn="fadeIn" animateOnce>
          <div className="box">

          <div className="text_area">
            <div className="title"> Acompanhamento de vendas</div>
            <div className="text">            
            Você poderá acompanhar em tempo real todas as vendas realizadas e comissões geradas em seu <a href="https://afiliados.mav.com.br">Painel de Afiliado</a> de maneira totalmente simples e sem nenhuma burocracia.
            </div>
            </div>

            <div className="image_area">
            <img src={panel} alt="question" />
            </div>
          </div>
          </ScrollAnimation>
      </div>

  </div>
  );
}

export default Funcionamento;