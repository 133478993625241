import React from 'react';

import'./style.css';
import'./responsive.css';
import banner from '../../Assets/img/banner.svg';

function Banner() {
  return (
    <div className="banner_wrapper">
        <div className="headline_wrapper">
            <div className="programa">PROGRAMA DE AFILIADOS</div>
            <div className="headline">Programa de Afiliados da MAV Tecnologia</div>
            <div className="sub_headline">Indique um cliente e ganhe a primeira mensalidade!</div>
            <div className ="button_wrapper">
            <a href="https://afiliados.mav.com.br/usuarios/cadastro"><button className='cadastre'>quero ser afiliado</button></a>
            </div>
        </div>
        <div className="images_banner_wrapper">
          <img src={banner} alt="banner"/>
        </div>
        
    </div>
  );
}

export default Banner;