import React,{useEffect} from 'react';
import $ from 'jquery'
import'./style.css';
import'./responsive.css';
import logo from '../../Assets/img/logo.svg'
function Menu() {

  useEffect(()=>{
    $(document).ready(function($){
      $(window).scroll(function() {    
       if ($(window).scrollTop() >= 20) {
         $('.menu_wrapper').addClass('back');
       }else{
           $('.menu_wrapper').removeClass('back');
       }
   });
 });
  },[])
  return (
    <div className="menu_wrapper">
        <div className="logo_wrapper">
            <img src={logo} alt="mav"/>
        </div>
        <div className="nav">
          <a href="https://afiliados.mav.com.br/usuarios/login"><button>entrar</button></a>
          <a href="https://afiliados.mav.com.br/usuarios/cadastro"><button>cadastre-se</button></a>
        </div>
    </div>
  );
}

export default Menu;